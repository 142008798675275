<template>
  <div class="nut-timeline">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'nut-timeline',
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

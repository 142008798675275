<template>
  <div class="nut-tab-panel hide">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'nut-tab-panel',
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss"></style>
